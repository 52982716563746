ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
html {
  scroll-behavior: auto;
}

@keyframes opacity {
  from {
    opacity: 1;
  }
  to { 
    opacity: 0;
  }
}

@keyframes lineHeight {
  from {
    line-height: 3.6rem;
  }
  to {
    line-height: 6rem;
  }
}

@keyframes reverse-opacity {
  0% {
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
}

@keyframes reverse-line-height {
  0% {
    line-height: 9rem;
  }
  100% {
    line-height: 3.6rem;
  }
}

@keyframes translate-y {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

#projects {
  scroll-margin-top: 100px;
}
 
/* temporary solution to customize carousel dots color */
.slick-dots button:before {
  opacity: .5 !important;
  color: white !important;
}

 .slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: white !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
}
.slick-dots li {
  width: 10px !important;
  height: 10px !important;
}

@keyframes skeletonBG {
  from {background-color: #F6F5F1;}
  to {background-color: white;}
}

.projectImage {
  transition: transform 0.5s ease-in-out;
}

.projectImage:hover {
  transform: scale(1.2);
}

.z-index--1 {
  z-index: -1;
}

/* hide glider slider ugly scrollbars */
.glider {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.glider::-webkit-scrollbar {
  display: none;
}


/* pagination */

.miiPagination .rc-pagination-item {
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "AcidGrotesk", Sans-Serif;
  font-weight: bold;
  border: none;
  color: #003F2C;
  border-radius: 0;
  text-decoration: none;
  transition: all 0.1s;
  border-color: #003F2C
}

.miiPagination .rc-pagination-item a{
  color: #003F2C;
}

.miiPagination .rc-pagination-item-active {
  border: none;
  border-bottom: 2px solid #003F2C;
}

.miiPagination .rc-pagination-item-active:focus a {
  color: #003F2C;
}

.miiPagination .rc-pagination-item-active:focus, .rc-pagination-item-active:hover {
  border-color: #003F2C
}

.miiPagination .rc-pagination-item:hover {
  border: none;
  border-bottom: 2px solid #003F2C;
}

.miiPagination .rc-pagination-item:hover a {
  color: #003F2C;
}

.miiPagination .rc-pagination-prev .rc-pagination-item-link {
  border: none
}

.miiPagination .rc-pagination-next .rc-pagination-item-link {
  border: none
}

.miiPagination .rc-pagination-disabled .rc-pagination-item-link {
  color: #003F2C;
  border: none
}

.mii-map-area {
  transition: all 0.3s ease;
}

.mii-map-area:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* Images overlay */
.img-gradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%;
  height:100%;
  display:inline-block;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.217) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.gradient {
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 1) 100%);
  -webkit-mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 1) 100%);

}

.brush::after {
  content: "";
  background-image: url('/images/brush-heading.svg');
  background-repeat: no-repeat;
  height: 10px;
  display: block;
  background-size: contain;
}

.withTopBrush::after {
  content: "";
  background-image: url('/images/up_brush_3.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  display: inline-flex;
  bottom: 10px;
  position: relative;

}

.withDownBrush::before {
  content: "";
  background-image: url('/images/down_brush_2.svg');
  background-repeat: no-repeat;
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  display: inline-flex;
  top: 7px;
  position: relative;
  margin-right: 3px;
}

.withUnderlineBrush {
  display: inline-flex;
  flex-direction: column;
}

.withUnderlineBrush::after {
  content: "";
  background-image: url('/images/brush_solo.svg');
  background-repeat: no-repeat;
  height: 4px;
  display: inline-flex;
}

[contentEditable=true]:empty:before {
  content: attr(placeholder);
}
